import { Injectable, NgZone, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AuthConstant } from "../../../constants/auth.constant";
import { EndPointService } from "../communication/endpoint-service";
import {
    HttpModificationService,
    MdDialogService
} from "../../../ajs-upgraded-providers";
import { SubmitManager } from "../communication/submitManager-service";
import { WelcomeMessageResource } from "../communication/resources/welcomeMessage-resource";
import { UserAuthenticationFactory } from "../communication/userAuthentication-factory";
import { AppScopeService } from "../communication/appScope-service";
import jwt_decode from 'jwt-decode';

import { WINDOW } from '../communication/window-provider';

declare var angular: angular.IAngularStatic;

// Modify the window interface so that TypeScript will compile when we add the userId for WalkMe
declare global {
    interface Window {
        tap_user_id: Number;
        _walkmeConfig: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    private intervalTimer: any;
    private jsonData: any;
    private endPointService: EndPointService = new EndPointService();

    constructor(
        private authConstants: AuthConstant,
        private httpModification: HttpModificationService,
        private mdDialog: MdDialogService,
        private submitManager: SubmitManager,
        private WelcomeMessageResourceFactory: WelcomeMessageResource,
        private http: HttpClient,
        private appScope: AppScopeService,
        private ngZone: NgZone,
        @Inject(WINDOW) private window: Window
    ) {
        this.jsonData = undefined;

        // If user is already logged in, attempt to handle any WalkMe-related tasks
        if (sessionStorage[authConstants.session.SESSION_DATA]) {
            this.handleWalkMe(JSON.parse(sessionStorage[authConstants.session.SESSION_DATA]));
        }

        this.ngZone.runOutsideAngular(() => {
            this.intervalTimer = setInterval(() => {
                // If the user is not logged in, we do not need to make any handshakes
                if (!sessionStorage[authConstants.session.SESSION_DATA]) {
                    return false;
                }
                let existingAccountHash = JSON.parse(localStorage[authConstants.session.ACCOUNT_HASH]);
                let existingSessionData = JSON.parse(sessionStorage[authConstants.session.SESSION_DATA]);

                if (existingAccountHash[existingSessionData.accountId]) {
                    this.http
                        .get(this.endPointService.handshakeEndPoint, {
                            params: new HttpParams({fromObject: { 'ignoreLoadingBar': 'true' }})
                        })
                        .subscribe(
                            (response:any) => {
                                let sessionData = JSON.parse(sessionStorage[authConstants.session.SESSION_DATA]);

                                // Go ahead and trigger change detection
                                this.ngZone.run(() => {
                                    this.upsertAccountHash(
                                        (typeof sessionData.accountId === 'number')? sessionData.accountId : parseInt(sessionData.accountId, 10),
                                        response
                                    );
                                });
                            },
                            (response: any) => {
                                return response;
                            }
                        );
                }
            }, 300000);
        });
    }

    handleWalkMe(sessionData:any) {
        // On page load, if the user is already logged in, we need to track their user ID, because WalkMe isn't sophisticated enough to use a basic cookie...
        this.window.tap_user_id = parseInt(sessionData.id, 10);

        // Also, check if we need to reload the WalkMe script
        if (sessionData.accountType === 'PROVIDER' && !sessionData.liteAccount) {
            // If this is test or dev cluster or local, NO WALKME
            if (
                [
                    'ui.test.tap.cts.comcast.net',
                    'ui.dev.tap.cts.comcast.net',
                    'localhost:3001',
                ].indexOf(window.location.host) > -1
            ) {
                console.log('WalkMe Disabled');
                // IF this is PreProd
            } else if (
                [
                    'distribution-preprod.advertising.comcasttechnologysolutions.com',
                ].indexOf(window.location.host) > -1
            ) {
                console.log('WalkMe Test Enabled');
                (function () {
                    var walkme = document.createElement('script');
                    walkme.type = 'text/javascript';
                    walkme.async = true;
                    walkme.src =
                        'https://cdn.walkme.com/users/721c3ec89a11459d84f750c06f9f7134/test/walkme_721c3ec89a11459d84f750c06f9f7134_https.js';
                    var s = document.getElementsByTagName('script')[0];
                    if (s.parentNode) {
                        s.parentNode.insertBefore(walkme, s);
                        window._walkmeConfig = { smartLoad: true };
                    }
                })();
                // If this is Prod (or anywhere else)
            } else {
                console.log('WalkMe Prod Enabled');
                (function () {
                    var walkme = document.createElement('script');
                    walkme.type = 'text/javascript';
                    walkme.async = true;
                    walkme.src =
                        'https://cdn.walkme.com/users/721c3ec89a11459d84f750c06f9f7134/walkme_721c3ec89a11459d84f750c06f9f7134_https.js';
                    var s = document.getElementsByTagName('script')[0];
                    if (s.parentNode) {
                        s.parentNode.insertBefore(walkme, s);
                        window._walkmeConfig = { smartLoad: true };
                    }
                })();
            }
        }
    }

    sessionKey(key:string|null = null) {
        return this.httpModification.sessionKey(key, this.authConstants.session.SESSION_KEY_NAME);
    };

    lastLoginName(loginName:string|null = null) {
        if (loginName) {
            localStorage.setItem(this.authConstants.session.LAST_LOGIN_USERNAME_KEY, loginName);
        } else {
            return localStorage.getItem(this.authConstants.session.LAST_LOGIN_USERNAME_KEY) || '';
        }
    };

    accountType(acctType:string|null = null) {
        if (acctType) {
            sessionStorage.setItem(this.authConstants.session.CURRENT_ACCOUNT_TYPE, acctType);
        } else {
            return sessionStorage.getItem(this.authConstants.session.CURRENT_ACCOUNT_TYPE) || '';
        }
    };

    setSessionData(data:any) {
        if (data) {
            // clear this out so it will be re-parsed on next read.
            this.jsonData = undefined;
            sessionStorage.setItem(this.authConstants.session.SESSION_DATA, JSON.stringify(data));
            this.setHeaders();
        }
    };
    clearBrandOnSwitch() {
        sessionStorage.removeItem(this.authConstants.session.CURRENT_ACCOUNT_TYPE);
        sessionStorage.removeItem(this.authConstants.session.BRAND_INFO);
        sessionStorage.removeItem(this.authConstants.session.BRAND_LOGO);
    }
    public getSessionData() {
        let data;
        // if we've already parsed this, don't bother to do it again.
        if (this.jsonData !== undefined) {
            data = this.jsonData;
        } else {
            let tempData = sessionStorage.getItem(this.authConstants.session.SESSION_DATA);
            if (tempData === null) {
                data = undefined;
            } else {
                data = JSON.parse(tempData);
            }
            // let's save this off so we don't have to re-read/parse every dang time.
            this.jsonData = data;
        }

        if (data && !this.sessionKey()) {
            this.sessionKey(data.sessionKey);
        }

        return data;
    };

    deleteSessionData() {
        // clear this out.
        this.jsonData = undefined;
        sessionStorage.removeItem(this.authConstants.session.SESSION_DATA);
        // Clear out any active submits or uploads we are tracking between sessions
        this.submitManager.cleanOut();
        this.httpModification.deleteSessionKey(this.authConstants.session.SESSION_KEY_NAME);
    };

    setHeaders() {
        let data = this.getSessionData();
        if (data) {
            this.sessionKey(data.sessionKey);
        }
    };

    upsertAccountHash(accountId:number, token:string) {
        let currentHash = JSON.parse(localStorage.getItem(this.authConstants.session.ACCOUNT_HASH) || '{}');

        currentHash[accountId] = token;

        localStorage.setItem(this.authConstants.session.ACCOUNT_HASH, JSON.stringify(currentHash));
    };

    openAccountSwitcher() {
        return this.mdDialog.openDialog({
            controller: 'AccountSwitchDialogController',
            controllerAs: 'vm',
            template: require('../navigation/accountSwitcher-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            fullscreen: true,
            locals: {
                preventClose: true,
            },
        });
    }

    /*
     * This function is used to attempt initialization of a user's session
     * */
    login(user:string, password:string) {
        let self = this;

        return new Promise((resolve, reject) => {
            self.http.post(
                self.endPointService.loginEndpoint,
                {
                    auth_user: user,
                    auth_password: password,
                },
                {}
            ).subscribe(
                (response:any) => {
                    let data = response.data;
                    let _userAuthentication = UserAuthenticationFactory.build(data);

                    _userAuthentication.noDashboards = false;

                    _userAuthentication.franchiseCustomer =
                        data.securityAccount && data.securityAccount.franchiseCustomer
                            ? data.securityAccount.franchiseCustomer
                            : false;

                    _userAuthentication.sessionKey = data.__sessionKey;
                    _userAuthentication.isFilteredUser = self._checkFilters(data);
                    self.lastLoginName(_userAuthentication.username);
                    self.accountType(_userAuthentication.accountType);
                    self.setSessionData(_userAuthentication);
                    // Attempt to handle any WalkMe-related tasks
                    self.handleWalkMe(_userAuthentication);
                    self.upsertAccountHash(
                        (typeof _userAuthentication.accountId === 'number')? _userAuthentication.accountId : parseInt(_userAuthentication.accountId, 10),
                        data.token
                    );

                    let continueOn = new Promise(function (resolve, reject) {
                        if (_userAuthentication.accountType) {
                            // They have an account already, and can continue on
                            resolve(true);
                        } else {
                            self.openAccountSwitcher().then(
                                function (newAccount:any) {
                                    _userAuthentication.accountType = newAccount.accountType;
                                    resolve(true);
                                },
                                function () {
                                    // Kick them out
                                    reject(false);
                                }
                            );
                        }
                    });

                    continueOn.then(
                        () => {
                            this.WelcomeMessageResourceFactory.get(
                                {
                                    accountType: _userAuthentication.accountType,
                                }).subscribe(
                                    (systemMessageResponse:any) => {
                                    if (data.eulaAcceptedFlag !== '1') {
                                        this.appScope.setSavedLocation('/eula');
                                    } else if (
                                        data.securityAccount &&
                                        data.securityAccount.accountType === 'RECEIVER' &&
                                        (!systemMessageResponse.message ||
                                            systemMessageResponse.message === '') &&
                                        (!data.securityAccount.dashboardWelcome ||
                                            data.securityAccount.dashboardWelcome ===
                                            '') &&
                                        data.securityAccount.dashboardCount === 0
                                    ) {
                                        this.appScope.setSavedLocation('/deliveries/list');
                                    }

                                    if (
                                        (!systemMessageResponse.message ||
                                            systemMessageResponse.message === '') &&
                                        (!data.securityAccount.dashboardWelcome ||
                                            data.securityAccount.dashboardWelcome ===
                                            '') &&
                                        data.securityAccount.dashboardCount === 0
                                    ) {
                                        _userAuthentication.noDashboards = true;
                                    }

                                    resolve(_userAuthentication);
                                }
                            );
                        },
                        function error() {
                            self.deleteSessionData();
                            reject(403);
                        }
                    );
                },
                function error(response:any) {
                    // If they can't login, then we should scrub the session, just in case.
                    self.deleteSessionData();

                    // Logic to handle which error message to show has been moved to the Node server.
                    // Ripley should not play a part in deciding what is wrong, it must simply make
                    // the user aware that there is a problem and deliver the bad news from Node.
                    reject(response.error);
                }
            );
        });
    };

    getDefaultRoute() {
        // Determine where home is
        let sessionData = this.getSessionData();
        let isFranchise = sessionData.franchiseCustomer;

        if (sessionData.liteAccount === true) {
            return {
                url: '/submit',
                state: 'liteSubmit',
            };
        } else if (sessionData.accountType === 'PROVIDER') {
            return {
                url: '/dashboard',
                state: 'dashboard',
            };
        } else if (sessionData.accountType === 'RECEIVER') {
            if (
                !(this.checkUserRole('orderitem.view', sessionData) && !isFranchise) &&
                !(this.checkUserRole('promo.view', sessionData) || isFranchise) &&
                this.checkUserRole('securityuser.modify', sessionData)
            ) {
                return {
                    url: '/settings',
                    state: 'settings',
                };
            }

            // This should already be se on login or account switch. It needs to check for either of the two types of messages or any existing dashboards
            if (sessionData.noDashboards) {
                if (isFranchise) {
                    return {
                        url: '/deliveries/promos',
                        state: 'promoList',
                    };
                } else {
                    return {
                        url: '/deliveries/list',
                        state: 'deliveryList',
                    };
                }
            }

            return {
                url: '/dashboard',
                state: 'dashboard',
            };
        } else if (
            sessionData.accountType &&
            (sessionData.accountType.replace(/_/g, '') === 'QCVENDOR' ||
                sessionData.accountType.replace(/_/g, '') === 'PRODUCTIONSERVICESVENDOR')
        ) {
            return sessionData.noDashboards &&
            (this.checkUserRole('spot.view', sessionData) ||
                sessionData.adminFlag == '1')
                ? { url: '/spots/list', state: 'spotList' }
                : { url: '/dashboard', state: 'dashboard' };
        } else {
            return {
                url: '/dashboard',
                state: 'dashboard',
            };
        }
    };

    getJwt():string {
        if (sessionStorage[this.authConstants.session.SESSION_DATA]) {
            let accountHash = JSON.parse(localStorage[this.authConstants.session.ACCOUNT_HASH]);
            let sessionData = JSON.parse(sessionStorage[this.authConstants.session.SESSION_DATA]);

            return accountHash[sessionData.accountId];
        } else {
            return '';
        }
    };

    switchAccount(newToken:string, newAccount:any) {
        let self = this;

        return new Promise((resolve, reject) => {
            // Update the account type, so it matches any changes
            self.accountType(newAccount.accountType);
            // Rebuild session data
            let oldSession = self.getSessionData();
            oldSession.accountId = newAccount.id;
            oldSession.accountName = newAccount.name;
            oldSession.accountType = newAccount.accountType;
            oldSession.liteAccount = newAccount.expressFlag;
            oldSession.destinationType = newAccount.destinationType;
            oldSession.ignoreDateRestriction = newAccount.ignoreDateRestriction;
            oldSession.strictIsci = newAccount.strictIsci;
            oldSession.franchiseCustomer = newAccount.franchiseCustomer;
            oldSession.asperaAccountDetails = newAccount.asperaAccountDetails;
            oldSession.account = newAccount;
            delete oldSession.activeUploads;

            this.WelcomeMessageResourceFactory.get(
                { accountType: newAccount.accountType }
            ).subscribe(
                function (systemMessageResponse:any) {
                    // Set the dashboard hiding code (for receivers with nothing to show)
                    oldSession.noDashboards =
                        (!systemMessageResponse.message ||
                            systemMessageResponse.message === '') &&
                        (!newAccount.dashboardWelcome ||
                            newAccount.dashboardWelcome === '') &&
                        newAccount.dashboardCount === 0;

                    self.setSessionData(oldSession);

                    self.upsertAccountHash(
                        (typeof newAccount.id === 'number')? newAccount.id : parseInt(newAccount.id, 10),
                        newToken
                    );

                    // Acknowledge account has switched successfully
                    resolve(true);
                }
            );
        });
    };

    /*
     * This function is used to invalidate a user's session
     * */
    logout() {
        return new Promise((resolve, reject) => {
            this.http.post(this.endPointService.logoutEndpoint, {}).subscribe(
                (data:any) => {
                    resolve(data);
                },
                (errorObject:any) => {
                    reject(errorObject);
                }
            );
            clearInterval(this.intervalTimer);
            this.deleteSessionData();
            sessionStorage.clear();
        });
    };

    /*
     * This function is used to determine if a user has a designated role
     * NOTE: Because this function is often passed by reference into aliases on controllers
     *       the `this` reference will be lost unless we make it an explicit arrow function
     *       For a helpful video on this, see here: https://www.youtube.com/watch?v=tvocUcbCupA&hd=1
     * */
    hasPermission=(roleArray:any):boolean => {
        let user = this.getSessionData();

        // don't know why we'd be in this situation, but better just fail
        if (!roleArray || !user) return false;

        // super user, go forth and conquer
        if (user.adminFlag == '1') return true;

        let hasPermission = false;
        for (let i = 0; i < roleArray.length; i++) {
            hasPermission = this.checkUserRole(roleArray[i], user);
            if (hasPermission) break;
        }

        return hasPermission;
    };

    /*
     *  function to loop through the user's roles
     * */
    checkUserRole(roleString:string, user:any) {
        if (!user.userRoles) {
            return false;
        }

        let hasPermission = false;
        // examples: 	spot.modify
        // 				spot.view
        //				super user
        //				superuser
        for (let i = 0; i < user.userRoles.length; i++) {
            if (typeof user.userRoles[i] === 'string') {
                // remove any spaces to normalize comparison
                let trimmedRole = user.userRoles[i].replace(' ', '');
                if (trimmedRole.toLowerCase() == roleString.toLowerCase()) {
                    hasPermission = true;
                    break;
                }
            } else {
                break;
            }
        }

        return hasPermission;
    };

    /*
     * This function is used to determine if a user is an admin/super user.
     * Can also be done above by passing "superuser" into the role array of hasPermission
     * */
    isAdmin() {
        let user = this.getSessionData();

        // don't know why we'd be in this situation, but better just fail
        if (!user) return false;

        // super user, go forth and conquer
        return user.adminFlag == '1';
    };
    /**
     * This function is used to decode jwt token and to determine if a user is an admin/super user.
     * 
     */
    isValidAdmin() {
        let jwtToken = this.getJwt();
        if(jwtToken){
            let decodedJWTToken: any = jwt_decode(jwtToken);
            return decodedJWTToken.adminFlag === '1' ? true : false;
        }else {
            return false;
        }
    }

    /*
     * This function is used to determine if a user is authorized or if they need
     * to return to the login screen
     * */
    authorize(loginRequired:any) {
        // Do complicated stuff
        let result = this.authConstants.permission.authorized.authorized,
            user = this.getSessionData();

        // If login is required, but there is no user logged in
        if (loginRequired === true && user === undefined) {
            result = this.authConstants.permission.authorized.loginRequired;
        }

        return result;
    };

    brandInfo(brand:any) {
        if (brand) {
            sessionStorage[this.authConstants.session.BRAND_INFO] = JSON.stringify(brand.data);
            if (brand.headerImage) {
                this.brandLogo(brand.headerImage);
            } else if (brand.image) {
                this.brandLogo(brand.image);
            }
        } else {
            return sessionStorage[this.authConstants.session.BRAND_INFO] &&
            sessionStorage[this.authConstants.session.BRAND_INFO] != 'undefined'
                ? JSON.parse(sessionStorage[this.authConstants.session.BRAND_INFO])
                : '';
        }
    };

    brandLogo(image:any) {
        if (image && image.data && image.data !== '') {
            sessionStorage[this.authConstants.session.BRAND_LOGO] = image.data;
        } else {
            return sessionStorage[this.authConstants.session.BRAND_LOGO] &&
            sessionStorage[this.authConstants.session.BRAND_LOGO] !== 'undefined'
                ? sessionStorage[this.authConstants.session.BRAND_LOGO]
                : '';
        }
    };

    private _checkFilters(data:any) {
        let isFiltered = false;
        data.PermissionContexts.map((obj:any) => {
            if (
                (obj.dataObjectClass === 'Agency' ||
                    obj.dataObjectClass === 'Advertiser') &&
                obj.dataObjectId !== 0
            ) {
                isFiltered = true;
            }
        });
        return isFiltered;
    };
}

angular.module('comcast.common.communication').factory('loginService', downgradeInjectable(LoginService));
